import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-skeleton-panel',
  templateUrl: './skeleton-panel.component.html',
  styleUrls: ['./skeleton-panel.component.less']
})
export class SkeletonPanelComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}