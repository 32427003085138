import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

import { HeaderComponent } from '../components';
import { SkeletonPanelComponent } from '../components/skeleton-panel/skeleton-panel.component';
import { DataService } from '../services';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [NgbNavModule, RouterModule, NgxSkeletonLoaderModule, CommonModule],
    declarations: [HeaderComponent, SkeletonPanelComponent],
    providers: [DataService],
    exports: [HeaderComponent, SkeletonPanelComponent]
})
export class SharedModule { }
