<div class="cards-container" >
    <ng-container *ngFor="let card of [1, 2, 3, 4, 5, 6, 7, 8]">
    <div class="card skeleton-card">
        <div class="card-header border-secondary">
        <ngx-skeleton-loader [theme]="{margin:'5px 0px 0px'}"></ngx-skeleton-loader>
        </div>
        <ul class="list-group list-group-flush card_body">
            <li class="list-group-item border-secondary align-content-center kudo-type">
                <ngx-skeleton-loader></ngx-skeleton-loader>
            </li>
            <li class="list-group-item border-secondary align-content-center panel-body">
                <ngx-skeleton-loader [theme]="{height:'125px', margin:'5px 0px 0px'}"></ngx-skeleton-loader>
            </li>
        </ul>
        <div class="card-footer border-secondary">
            <ngx-skeleton-loader [theme]="{margin:'5px 0px 0px'}"></ngx-skeleton-loader>
        </div>
    </div>
    </ng-container>
</div>